const ROOT = '/';
const AUTH = `${ROOT}auth`;
const SIGN_UP = `${ROOT}signup`;
const SIGN_UP_CONFIRM = `${ROOT}signup/account-confirm-email/:id`;
const ACCOUNT = `${ROOT}account`;
const NEW_GREENHOUSE = `${ROOT}new-greenhouse`;
const GREENHOUSE_MANAGEMENT = `${ROOT}greenhouse-management`;
const GREENHOUSE_DETAILS = `${GREENHOUSE_MANAGEMENT}/details/:id`;
const TERMS = `${ROOT}terms`;
const POLICY = `${ROOT}policy`;

const PASSWORD_ROOT = `${ROOT}password`;
const RESET_PASSWORD = `${PASSWORD_ROOT}/reset`;
const FORGOT_PASSWORD = `${PASSWORD_ROOT}/forgot`;
const CONFIRM_PASSWORD = `${PASSWORD_ROOT}/reset/confirm/:uid/:token`;

export const ROUTES = {
  ROOT,
  AUTH,
  SIGN_UP,
  SIGN_UP_CONFIRM,
  ACCOUNT,
  GREENHOUSE_MANAGEMENT,
  GREENHOUSE_DETAILS,
  NEW_GREENHOUSE,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  CONFIRM_PASSWORD,
  TERMS,
  POLICY
};
