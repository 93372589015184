import React, { useEffect, useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import { useSelector } from 'react-redux';

import { useUserActions, selectUser } from 'state';
import { ROUTES } from '_constants';
import { Route } from './Route';
import {
  PageAuth,
  PageMain,
  Account,
  ChangePassword,
  ForgotPassword,
  Terms,
  Policy,
  GreenhouseManagement,
  NewGreenhouse,
  GreenhouseDetails
} from 'pages';
import { Spinner } from 'components/_shared/Spinner';

const { REACT_APP_HOTJAR_ID, REACT_APP_HOTJAR_SV } = process.env;

export const Routes = () => {
  const { isAuthorized, email } = useSelector(selectUser);
  const { getCurrentUser } = useUserActions();
  const [load, setLoad] = useState(isAuthorized && !email);

  useEffect(() => {
    if (isAuthorized) {
      setLoad(true);
      getCurrentUser().finally(() => setLoad(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (REACT_APP_HOTJAR_ID && REACT_APP_HOTJAR_SV) {
      hotjar.initialize(REACT_APP_HOTJAR_ID, REACT_APP_HOTJAR_SV);
    }
  }, []);

  if (load) return <Spinner />;

  if (isAuthorized)
    return (
      <Switch>
        <Route exact path={ROUTES.ROOT} component={PageMain} />
        <Route path={ROUTES.ACCOUNT} component={Account} />
        <Route path={ROUTES.RESET_PASSWORD} component={ChangePassword} />
        <Route isPrivate path={ROUTES.GREENHOUSE_DETAILS} component={GreenhouseDetails} />
        <Route
          isPrivate
          path={ROUTES.GREENHOUSE_MANAGEMENT}
          component={GreenhouseManagement}
        />
        <Route isPrivate path={ROUTES.NEW_GREENHOUSE} component={NewGreenhouse} />
        <Redirect to={ROUTES.ROOT} />
      </Switch>
    );

  return (
    <Switch>
      <Route path={ROUTES.TERMS} component={Terms} />
      <Route path={ROUTES.POLICY} component={Policy} />
      <Route path={ROUTES.AUTH} component={PageAuth} />
      <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route path={ROUTES.CONFIRM_PASSWORD} component={ChangePassword} />
      <Redirect to={ROUTES.AUTH} />
    </Switch>
  );
};
