import { cloneElement } from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import { shouldForwardProp } from 'utils';

export const PageHeader = styled(({ children, ...props }) => {
  const className = cn(children.props.className, props.className);
  return cloneElement(children, { ...props, className });
})``;

export const StyledPage = styled.div.withConfig({ shouldForwardProp })`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const PageContent = styled.div`
  ${({ fullHeight }) => (fullHeight ? `flex-grow: 1` : ``)};
  display: flex;
`;
