import styled, { css } from 'styled-components';
import { em } from 'styles';

import { Select } from 'components/_shared/Select';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const StyledSelect = styled(Select)`
  ${({ theme }) => css`
    display: inline-block;
    min-width: ${em(200)};
    box-shadow: ${theme.shadows()[0]};
    background: ${theme.colors.white};
    border-radius: ${em(5)};
  `}
`;

export const Header = styled.h2`
  font-size: ${em(20)};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  margin-bottom: ${em(8)};
`;

export const Title = styled.h3`
  margin: ${em(14)} 0;
  font-size: ${em(14)};
`;
