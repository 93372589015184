import React from 'react';

import { useUserActions } from 'state';
import { FormPage } from 'components/_shared/Page';
import { AuthForm } from 'components/Auth';
import { Paper } from 'components/_shared/Paper';

export const PageAuth = ({ ...props }) => {
  const { isLoading, error, login, getCurrentUser } = useUserActions();

  const handleSubmit = async values => {
    await login(values);
    await getCurrentUser();
  };

  return (
    <FormPage {...props} header={false}>
      <Paper padding={4}>
        <AuthForm isLoading={isLoading} error={error} onSubmit={handleSubmit} />
      </Paper>
    </FormPage>
  );
};
