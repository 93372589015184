import styled from 'styled-components';
import { em } from 'styles';
import { Link } from 'components/_shared/Link';
import { Form } from 'components/_shared/Form';

export const ForgotPassLinkWrap = styled.div`
  text-align: right;
`;

export const StyledLink = styled(Link)`
  font-size: ${em(14)};
`;

export const StyledForm = styled(Form)`
  max-width: ${({ theme }) => theme.formSize.width};
`;
