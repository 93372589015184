import React from 'react';

import { Page } from 'components/_shared/Page';
import { Sidebar } from 'components/_shared/Sidebar';
import { GreenhousePlan } from 'components/GreenhousePlan';
import { GreenhouseStatistics } from 'components/GreenhouseStatistics';

import { Content } from './PageMain.styles';

export const PageMain = ({ ...props }) => {
  return (
    <Page fullHeight {...props}>
      <Sidebar />
      <Content>
        <GreenhouseStatistics />
        <GreenhousePlan />
      </Content>
    </Page>
  );
};
