import styled, { css } from 'styled-components';

export const StyledDivider = styled.div`
  ${({ height, width }) => css`
    height: ${height ?? 1}px;
    width: ${width ?? '100%'};
    margin: auto;
    background: #b4c0ea;
  `}
`;
