export const CHART_VALUES = [
  {
    name: 'Bud',
    color: '#5A6ACF'
  },
  {
    name: 'Partially open flower',
    color: '#8593ED'
  },
  {
    name: 'Fully open flower',
    color: '#C7CEFF'
  }
];

export const CHART_LAYOUT = {
  plot_bgcolor: 'transparent',
  paper_bgcolor: 'transparent',
  margin: { l: 35, r: 10, t: 30, b: 20 },
  hoverlabel: {
    bgcolor: '#37375C',
    font: { size: 14, color: '#ffffff' },
    bordercolor: '#37375C'
  }
};

export const CHART_CONFIG = { displaylogo: false, displayModeBar: false };

export const CHART_DAYS = 7;
export const ALL_ZONES = 'all_zones';
