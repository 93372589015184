import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';

import { Form } from 'components/_shared/Form';
import { FormFields } from './FormFields';
import { FIELD_VALIDATION } from '_constants';
import { getGeojson, getGreenhouseArea } from 'utils';

import { StepsButton } from '../NewGreenhouseSteps.styles';
import {
  Header,
  Title,
  WarningText,
  StyledGreenhousePlan,
  StyledMap,
  PlanFooter,
  Controls,
  Area,
  Container
} from './GreenhousePlan.styles';

const validationSchema = Yup.object().shape({
  rows: Yup.array().of(
    Yup.object().shape({
      width: Yup.number()
        .min(1, FIELD_VALIDATION.MIN_NUMBER)
        .required(FIELD_VALIDATION.REQUIRED),
      length: Yup.number()
        .min(1, FIELD_VALIDATION.MIN_NUMBER)
        .required(FIELD_VALIDATION.REQUIRED)
    })
  )
});

export const GreenhousePlan = ({ onSubmit, rows, onBack, step, lastStep, onNext }) => {
  const [isSaved, setSaved] = useState(rows !== undefined);
  const [geojson, setGeojson] = useState(() =>
    rows === undefined ? undefined : getGeojson(rows)
  );

  const handleSubmit = values => {
    onSubmit(values);
    setSaved(true);
    setGeojson(getGeojson(values.rows));
  };

  const greenhouseArea = useMemo(() => getGreenhouseArea(rows), [rows]);

  return (
    <StyledGreenhousePlan>
      <Header>Greenhouse plan</Header>
      <Title>
        Please specify the length, width for each row and add the correct number of rows.
      </Title>
      <Header>Important*</Header>
      <WarningText>
        The map will not show the path between the rows - for correct data display
      </WarningText>
      <Form
        validationSchema={validationSchema}
        initialValues={{ rows: rows ?? [{ length: '', width: '' }] }}
        onSubmit={handleSubmit}
        params={{ validateOnChange: true }}
      >
        {({ values, isValid, dirty }) => {
          const сanSave = (!dirty && rows) || (dirty && isValid);
          return (
            <>
              <Container>
                <StyledMap data={geojson} />
                <FormFields values={values} />
              </Container>
              <PlanFooter>
                <div>
                  <Header>Total area of greenhouse</Header>
                  <Area>{greenhouseArea.m} m2</Area>
                  <Area>{greenhouseArea.ha} ha</Area>
                </div>
                <Controls>
                  <StepsButton variant='primary' onClick={onBack} margin={15}>
                    Back
                  </StepsButton>
                  {isSaved && !dirty ? (
                    <StepsButton variant='primary' onClick={onNext}>
                      Next step {step} | {lastStep}
                    </StepsButton>
                  ) : (
                    <StepsButton
                      variant={сanSave ? 'primary' : 'secondary'}
                      type='submit'
                    >
                      Save
                    </StepsButton>
                  )}
                </Controls>
              </PlanFooter>
            </>
          );
        }}
      </Form>
    </StyledGreenhousePlan>
  );
};
