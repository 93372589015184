import styled, { css } from 'styled-components';
import { em } from 'styles';

export const Header = styled.h2`
  ${({ theme, bold }) => css`
    color: ${theme.colors.primary.p2};
    font-size: ${em(20)};
    font-weight: ${theme.fontWeights[bold ? 1 : 0]};
  `}
`;

export const Label = styled.div`
  ${({ theme }) => css`
    margin-top: ${em(12)};
    margin-bottom: ${em(4)};
    color: ${theme.colors.primary.p1};
    font-size: ${em(13)};
  `}
`;

export const Description = styled.pre`
  white-space: pre-wrap;
  font-size: ${em(13)};
`;

export const DateContainer = styled.div`
  display: flex;
  ${Label} {
    margin: 0;
  }
`;
