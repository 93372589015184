import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectUser, useGreenhouseActions } from 'state';
import { StepsHeader } from './StepsHeader';
import { GreenhouseName } from './GreenhouseName';
import { GreenhousePlan } from './GreenhousePlan';
import { GreenhouseSubscription } from './GreenhouseSubscription';

import { Wrapper, StyledPaper } from './NewGreenhouseSteps.styles';
import { getGeojson } from 'utils';
import { ROUTES } from '_constants';

const STEPS_COUNT = 3;

export const NewGreenhouseSteps = () => {
  const history = useHistory();
  const { customer } = useSelector(selectUser);
  const { addLocation } = useGreenhouseActions();
  const [greenhouseValues, setGreenhouseValues] = useState({});
  const [currentStep, setCurrentStep] = useState(1);

  const goNextStep = () => setCurrentStep(x => Math.min(x + 1, STEPS_COUNT));
  const goPrevStep = () => setCurrentStep(x => Math.max(x - 1, 1));
  const handleSubmitName = value => {
    setGreenhouseValues(state => ({ ...state, ...value }));
    goNextStep();
  };
  const handleSubmitRows = value => {
    setGreenhouseValues(state => ({ ...state, ...value }));
  };
  const handleSubmitNewGreenhouse = async () => {
    const data = {
      external_id: greenhouseValues.id ? greenhouseValues.id : greenhouseValues.name,
      name: greenhouseValues.name,
      plan: getGeojson(greenhouseValues.rows),
      customer: customer?.[0].id,
      billing_plan: greenhouseValues.subscriptionId ?? ''
    };
    await addLocation(data);
    history.push(ROUTES.ROOT);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <GreenhouseName
            onSubmit={handleSubmitName}
            defaultValues={{ name: greenhouseValues.name, id: greenhouseValues.id }}
            step={currentStep}
            lastStep={STEPS_COUNT}
          />
        );
      case 2:
        return (
          <GreenhousePlan
            onBack={goPrevStep}
            step={currentStep}
            lastStep={STEPS_COUNT}
            onSubmit={handleSubmitRows}
            onNext={goNextStep}
            rows={greenhouseValues.rows}
          />
        );
      case 3:
        return (
          <GreenhouseSubscription
            onBack={goPrevStep}
            subscriptionId={greenhouseValues.subscriptionId}
            onSubscriptionChange={handleSubmitRows}
            onSubmit={handleSubmitNewGreenhouse}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <StyledPaper padding={1}>
        <StepsHeader />
        {renderStep()}
      </StyledPaper>
    </Wrapper>
  );
};
