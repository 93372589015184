import styled from 'styled-components';
import { em } from 'styles';

import { Paper } from 'components/_shared/Paper';
import { FormField } from 'components/_shared/Form';
import { Button } from 'components/_shared/Button';

export const Modal = styled(Paper)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: ${em(450)};
  z-index: ${({ theme }) => theme.zIndexes[1]}; ;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${em(10)};
`;

export const TitleWrap = styled.div`
  display: flex;
  margin-bottom: ${em(10)};
`;

export const Title = styled.h2`
  margin-left: ${em(5)};
  padding-top: ${em(4)};
  color: ${({ theme }) => theme.colors.primary.p2};
`;

export const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: ${em(10)};
  & button {
    min-width: ${em(125)};
  }
`;

export const StyledField = styled(FormField)`
  min-width: ${em(190)};
  color: ${({ theme }) => theme.colors.nature.n3};
`;

export const CloseButton = styled(Button)`
  box-shadow: ${({ theme }) => theme.shadows()[0]};
  border-radius: 50%;
  padding: ${em(15)};
`;
