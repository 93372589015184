import styled, { css } from 'styled-components';
import { em } from 'styles';

import { Typography } from '../Typography';

export const SidebarBody = styled.div`
  flex-grow: 1;
`;

export const SidebarHeading = styled(Typography).attrs({
  element: 'h2',
  variant: 'h2'
})`
  margin-bottom: ${em(16)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BasicSidebar = styled.aside`
  ${({ theme }) => css`
    width: ${em(300)};
    min-width: ${em(300)};
    box-shadow: ${theme.shadows()[0]};
  `}
`;

export const StyledSidebar = styled(BasicSidebar)`
  display: flex;
  flex-direction: column;
  padding: ${em(16)} 0;
  background: ${({ theme }) => theme.colors.white};
`;
