import { isArray, isEmpty, isFunction, mergeWith } from 'lodash-es';

import { MONTHS, HA_TO_METERS_FACTOR } from '_constants';

export const withFunction = (value, args) => {
  return isFunction(value) ? value(args) : value;
};

export const mergeObjects = (initialObject, source, customizer) => {
  let _source = source;

  if (isArray(source)) {
    _source = source.reduce((initialObject, source) => {
      return !source ? initialObject : mergeObjects(initialObject, source);
    }, {});
  }

  if (isFunction(source)) _source = source(initialObject);

  const defaultCustomizer = (value, sourceValue) => {
    if (isArray(value) && !isEmpty(sourceValue)) return sourceValue;
    if (isFunction(value) && sourceValue) return sourceValue;
  };

  return mergeWith({}, initialObject, _source, customizer || defaultCustomizer);
};

export const getTransactionDate = date => {
  const formatConfig = { month: 'long', year: 'numeric' };
  return new Intl.DateTimeFormat('en-US', formatConfig).format(new Date(date));
};

export const getDateRangeLabel = (start, end) => {
  const dateStart = new Date(start);
  const dateEnd = new Date(end);
  const monthStart =
    dateStart.getMonth() === dateEnd.getMonth() ? '' : ` ${MONTHS[dateStart.getMonth()]}`;
  const monthEnd = MONTHS[dateEnd.getMonth()];
  return `${dateStart.getDate()}${monthStart} - ${dateEnd.getDate()} ${monthEnd}, ${dateEnd.getFullYear()}`;
};

export const getGeojson = rows => {
  const scale = 100;
  const { features } = rows.reduce(
    ({ offset, features }, row) => {
      const width = row.width / scale;
      const length = row.length / scale;
      const feature = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [0 + offset, 0],
              [width + offset, 0],
              [width + offset, length],
              [0 + offset, length],
              [0 + offset, 0]
            ]
          ]
        },
        properties: {} // required field, fill on BE
      };
      return {
        offset: offset + width,
        features: features.concat(feature)
      };
    },
    { offset: 0, features: [] }
  );

  return { type: 'FeatureCollection', features };
};

export const getGreenhouseArea = rows => {
  if (!rows) return { m: 0, ha: 0 };
  const area = rows.reduce((sum, row) => sum + row.width * row.length, 0);
  return { m: area, ha: area / HA_TO_METERS_FACTOR };
};
