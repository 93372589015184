import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ROUTES } from '_constants';
import { Icon } from 'components/_shared/Icon';
import { Divider } from 'components/_shared/Divider';
import { useGreenhouseActions, selectGreenhouse } from 'state';

import {
  StyledLink,
  GreenHousesHeader,
  Title,
  HouseList,
  HouseListItem
} from './GreenhouseList.styles';

export const GreenhouseList = () => {
  const { getLocations, setSelectedGreenhouse } = useGreenhouseActions();
  const { locations, selectedGreenhouse } = useSelector(selectGreenhouse);

  useEffect(() => {
    if (!locations.length) getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledLink to={l => `${ROUTES.ROOT}${ROUTES.ROOT === l.pathname ? l.search : ''}`}>
      <GreenHousesHeader>
        <Icon>House</Icon>
        <Title>Greenhouse list</Title>
      </GreenHousesHeader>
      <Divider width='90%' />
      <HouseList>
        {locations.map(item => (
          <HouseListItem
            selected={selectedGreenhouse === item.id}
            key={item.id}
            onClick={() => setSelectedGreenhouse(item.id)}
          >
            {item.name}
          </HouseListItem>
        ))}
      </HouseList>
      <Divider width='90%' />
    </StyledLink>
  );
};
