import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useUserActions, selectUser } from 'state';
import { ROUTES, USER_PERMISSIONS } from '_constants';
import { GreenhouseList } from 'components/GreenhouseList';
import { Divider } from 'components/_shared/Divider';

import { StyledButton, AddButton, NewGreenhouse } from './SidebarMenu.styles';

export const SidebarMenu = () => {
  const { pathname } = useLocation();
  const { toggleLogoutModal } = useUserActions();
  const user = useSelector(selectUser);

  const isAdmin = user.permissions?.includes(USER_PERMISSIONS.ADD);

  return (
    <div>
      <GreenhouseList />
      {isAdmin && user.customer && (
        <>
          <NewGreenhouse>
            <AddButton variant='primary' to={ROUTES.NEW_GREENHOUSE}>
              Add new greenhouse
            </AddButton>
          </NewGreenhouse>
          <Divider width='90%' />
          <StyledButton
            isActive={pathname.startsWith(ROUTES.GREENHOUSE_MANAGEMENT)}
            icon='Document'
            to={ROUTES.GREENHOUSE_MANAGEMENT}
          >
            Greenhouse management
          </StyledButton>
        </>
      )}

      <StyledButton
        isActive={pathname === ROUTES.ACCOUNT}
        icon='Settings'
        to={ROUTES.ACCOUNT}
      >
        Profile
      </StyledButton>
      <StyledButton icon='LogOut' onClick={toggleLogoutModal}>
        Log Out
      </StyledButton>
    </div>
  );
};
