import styled, { css } from 'styled-components';

import { Paper } from 'components/_shared/Paper';
import { Button } from 'components/_shared/Button';
import { em } from 'styles';

export const Wrapper = styled.div`
  max-width: ${em(1240)};
  min-width: ${em(500)};
  margin: auto;
`;

export const StyledPaper = styled(Paper)`
  margin: ${em(10)};
`;

export const StepsButton = styled(Button)`
  ${({ margin, variant, theme }) => css`
    margin: 0 ${em(margin ?? 0)};
    padding: ${em(10)} ${em(40)};
    ${variant === 'secondary' && `border: 1px solid ${theme.colors.primary.p2}`}
  `}
`;
