import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { chunk } from 'lodash-es';
import qs from 'qs';

import { useGreenhouseActions, selectGreenhouse } from 'state';
import { CHART_DAYS, ALL_ZONES } from './constants';
import { TotalFlowersChart } from './TotalFlowersChart';
import { AverageFlowersChart } from './AverageFlowersChart';

import { Wrapper } from './GreenhouseStatistics.styles';

export const GreenhouseStatistics = () => {
  const history = useHistory();
  const location = useLocation();
  const { selectedGreenhouse, selectedZone, missionDates, zoneStatistic } =
    useSelector(selectGreenhouse);
  const { setMissionDates, setZoneStatistic } = useGreenhouseActions(selectGreenhouse);
  const [daysRange, setDaysRange] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);

  useEffect(() => {
    if (selectedGreenhouse) setMissionDates(selectedGreenhouse);
  }, [selectedGreenhouse, setMissionDates]);

  const transformedDates = useMemo(
    () => chunk([...missionDates].reverse(), CHART_DAYS).map(x => x.reverse()),
    [missionDates]
  );

  useEffect(() => {
    if (!transformedDates.length) {
      setDaysRange([]);
      return;
    }
    let defaultDates = transformedDates[0];
    const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (urlParams.start !== undefined) {
      const range = transformedDates.find(item => item[0] === urlParams.start);
      defaultDates = range ?? transformedDates[0];
    }
    setZoneStatistic(
      selectedGreenhouse,
      defaultDates[0],
      defaultDates[defaultDates.length - 1]
    );
    setDaysRange(defaultDates);
    setSelectedDay(defaultDates[defaultDates.length - 1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedDates]);

  useEffect(() => {
    if (!selectedGreenhouse) return;
    history.push({
      search: qs.stringify({ greenhouse: selectedGreenhouse, start: daysRange[0] })
    });
  }, [selectedGreenhouse, daysRange, history]);

  const totalFlowersData = useMemo(() => {
    const flowers = [[], [], []];
    daysRange.forEach(date => {
      const zoneId = String(selectedZone ?? ALL_ZONES);
      const dayStatistic = zoneStatistic.find(
        item => item.date === date && item.zone_external_id === zoneId
      );
      flowers.forEach((arr, i) =>
        arr.push(dayStatistic?.aggregated_detections[`bud_stage_${i}`] ?? 0)
      );
    });

    return { x: daysRange.map(d => new Date(d).getDate()), y: flowers };
  }, [daysRange, selectedZone, zoneStatistic]);

  const handleSelectChange = useCallback(
    value => {
      setZoneStatistic(selectedGreenhouse, value[0], value[value.length - 1]);
      setDaysRange(value);
      setSelectedDay(value[value.length - 1]);
    },
    [selectedGreenhouse, setZoneStatistic]
  );

  if (!selectedGreenhouse) return null;

  return (
    <Wrapper>
      <TotalFlowersChart
        axisData={totalFlowersData}
        dateList={transformedDates}
        onPeriodChange={handleSelectChange}
        selectedRange={daysRange}
      />
      {zoneStatistic.length && daysRange.length && (
        <AverageFlowersChart
          zoneStatistic={zoneStatistic}
          period={daysRange}
          selectedZone={selectedZone}
          selectedDay={selectedDay}
          onDayChange={setSelectedDay}
        />
      )}
    </Wrapper>
  );
};
