import { axiosInstance } from 'api/instance';
import { greenhouseEndpoints } from './greenhouse.endpoints';

const getLocations = () => axiosInstance.get(greenhouseEndpoints.location);

const createNewLocation = data => axiosInstance.post(greenhouseEndpoints.location, data);

const deleteLocation = id =>
  axiosInstance.delete(`${greenhouseEndpoints.location}/${id}`);

const patchLocation = (id, data) =>
  axiosInstance.patch(`${greenhouseEndpoints.location}/${id}`, data);

const getMissions = locationId =>
  axiosInstance.get(greenhouseEndpoints.missions, { params: { location: locationId } });

const getStatistics = (locationId, start, end) =>
  axiosInstance.get(greenhouseEndpoints.detections, {
    params: { location: locationId, start_date: start, end_date: end }
  });

const getSubscriptions = () => axiosInstance.get(greenhouseEndpoints.subscriptions);

export const greenhouseRequests = {
  getLocations,
  getMissions,
  getStatistics,
  getSubscriptions,
  createNewLocation,
  deleteLocation,
  patchLocation
};
