import React from 'react';

import { Page } from 'components/_shared/Page';
import { Sidebar } from 'components/_shared/Sidebar';
import { NewGreenhouseSteps } from 'components/NewGreenhouseSteps';

export const NewGreenhouse = props => {
  return (
    <Page fullHeight {...props}>
      <Sidebar />
      <NewGreenhouseSteps />
    </Page>
  );
};
