import React from 'react';

import { Header } from 'components/Header';

import { PageHeader, StyledPage, PageContent } from './Page.styles';

export const Page = ({ children, header = <Header />, fullHeight, ...props }) => {
  return (
    <StyledPage {...props}>
      {header && <PageHeader>{header}</PageHeader>}
      <PageContent fullHeight={fullHeight}>{children}</PageContent>
    </StyledPage>
  );
};
