import React from 'react';
import { FieldArray } from 'formik';

import {
  FieldsContainer,
  FieldsetWrap,
  StyledFieldset,
  Field,
  DeleteRowBtn,
  AddRowBtn
} from './GreenhousePlan.styles';

export const FormFields = ({ values }) => {
  return (
    <FieldArray name='rows'>
      {({ remove, push }) => {
        return (
          <FieldsContainer>
            {values.rows.map((_, i) => (
              <FieldsetWrap key={i}>
                <StyledFieldset>
                  <Field
                    label='Row length'
                    type='number'
                    name={`rows.${i}.length`}
                    placeholder='0 m'
                    min={1}
                  />
                  <Field
                    label='Row width'
                    type='number'
                    name={`rows.${i}.width`}
                    placeholder='0 m'
                    min={1}
                  />
                </StyledFieldset>
                {values.rows.length > 1 && (
                  <DeleteRowBtn
                    variant='secondary'
                    icon='Cross'
                    onClick={() => remove(i)}
                  />
                )}
              </FieldsetWrap>
            ))}
            <AddRowBtn
              type='button'
              variant='secondary'
              icon='Plus'
              onClick={() => push({ length: '', width: '' })}
            />
          </FieldsContainer>
        );
      }}
    </FieldArray>
  );
};
