import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { uniq } from 'lodash-es';
import qs from 'qs';

import { API } from 'api';
import { greenhouseActions } from './greenhouse.slice';

export const useGreenhouseActions = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const getLocations = useCallback(async () => {
    const resp = await API.greenhouse.getLocations();
    const urlParams = qs.parse(search, { ignoreQueryPrefix: true });
    const selectedLocation = resp.data.find(
      ({ id }) => id === Number(urlParams?.greenhouse)
    );
    const locationsData = {
      locations: resp.data,
      selected: selectedLocation?.id ?? resp.data[0]?.id
    };
    dispatch(greenhouseActions.setLocations(locationsData));
  }, [dispatch, search]);

  const addLocation = useCallback(
    async location => {
      const resp = await API.greenhouse.createNewLocation(location);
      dispatch(greenhouseActions.addLocation(resp.data));
    },
    [dispatch]
  );

  const deleteLocation = useCallback(
    async id => {
      await API.greenhouse.deleteLocation(id);
      dispatch(greenhouseActions.deleteLocation(id));
    },
    [dispatch]
  );

  const updateLocation = useCallback(
    async (id, data) => {
      const resp = await API.greenhouse.patchLocation(id, data);
      dispatch(greenhouseActions.updateLocation(resp.data));
    },
    [dispatch]
  );

  const setSelectedGreenhouse = useCallback(
    id => {
      dispatch(greenhouseActions.setSelectedGreenhouseId(id));
    },
    [dispatch]
  );

  const setSelectedZone = useCallback(
    zone => {
      dispatch(greenhouseActions.setSelectedZone(zone));
    },
    [dispatch]
  );

  const setMissionDates = useCallback(
    async locationId => {
      const resp = await API.greenhouse.getMissions(locationId);
      const sorted = resp.data.sort(
        (a, b) => new Date(a.datetime) - new Date(b.datetime)
      );
      const dates = uniq(sorted.map(item => item.datetime.split('T')[0]));
      dispatch(greenhouseActions.setMissionDates(dates));
    },
    [dispatch]
  );

  const setZoneStatistic = useCallback(
    async (locationId, start, end) => {
      const resp = await API.greenhouse.getStatistics(locationId, start, end);
      dispatch(greenhouseActions.setZoneStatistic(resp.data));
    },
    [dispatch]
  );

  const loadSubscriptions = useCallback(async () => {
    const resp = await API.greenhouse.getSubscriptions();
    dispatch(greenhouseActions.setSubscriptions(resp.data));
  }, [dispatch]);

  return {
    getLocations,
    addLocation,
    deleteLocation,
    setSelectedGreenhouse,
    setSelectedZone,
    setMissionDates,
    setZoneStatistic,
    loadSubscriptions,
    updateLocation
  };
};
