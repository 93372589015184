import React from 'react';

import { SidebarMenu } from './SidebarMenu';

import { SidebarBody, SidebarHeading, StyledSidebar } from './Sidebar.styles';

export const Sidebar = ({ className, heading, ...props }) => {
  return (
    <StyledSidebar {...props} className={className}>
      {heading && <SidebarHeading>{heading}</SidebarHeading>}
      <SidebarBody>
        <SidebarMenu />
      </SidebarBody>
    </StyledSidebar>
  );
};
