import React from 'react';
import * as Yup from 'yup';

import { Form } from 'components/_shared/Form';
import { FIELD_VALIDATION } from '_constants';

import { Wrapper, StyledField, FieldText, StyledButton } from './GreenhouseName.styles';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required(FIELD_VALIDATION.REQUIRED)
});

export const GreenhouseName = ({ onSubmit, defaultValues, step, lastStep }) => {
  const handleSubmit = v => {
    onSubmit({ name: v.name.trim(), id: v.id.trim() });
  };

  return (
    <Form
      validationSchema={validationSchema}
      initialValues={defaultValues.name ? defaultValues : { name: '', id: '' }}
      onSubmit={handleSubmit}
      params={{ validateOnChange: true }}
    >
      {({ isValid, dirty }) => {
        const canSubmit = (defaultValues.name && !dirty) || (dirty && isValid);
        return (
          <Wrapper>
            <div>
              <StyledField
                label='Name of greenhouse'
                type='text'
                name='name'
                placeholder='Name*'
              />
              <FieldText>
                Fill in this field if you are using the Corvus image provider, otherwise
                leave this field blank.
              </FieldText>
              <StyledField label='External ID' type='text' name='id' placeholder='ID' />
            </div>
            <StyledButton type='submit' variant={canSubmit ? 'primary' : 'secondary'}>
              Next step {step} | {lastStep}
            </StyledButton>
          </Wrapper>
        );
      }}
    </Form>
  );
};
