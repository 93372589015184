import React, { useState } from 'react';

import { HA_TO_METERS_FACTOR, ROUTES } from '_constants';
import { Subscription } from './Subscription';
import { EditModal } from './EditModal';

import {
  Wrapper,
  Head,
  Container,
  Header,
  Size,
  StyledMap,
  EditButton,
  BackButton
} from './GreenhouseDetails.styles';

export const GreenhouseDetails = ({ greenhouse, subscription }) => {
  const [isEditName, setIsEditName] = useState(false);
  const sizeInMeters = (greenhouse.area_in_ha * HA_TO_METERS_FACTOR).toFixed(3);

  return (
    <Wrapper>
      <Head>
        <div>
          <Header bold>{greenhouse.name}</Header>
          <EditButton icon='Pencil' onClick={() => setIsEditName(true)}>
            Edit name of greenhouse
          </EditButton>
        </div>
        <BackButton to={ROUTES.GREENHOUSE_MANAGEMENT} variant='primary'>
          Back
        </BackButton>
      </Head>
      <Container>
        <div>
          <Header bold>Area: </Header>
          <Size>
            {greenhouse.area_in_ha} ha \ {sizeInMeters} m2
          </Size>
          <StyledMap data={greenhouse.plan} />
        </div>
        {subscription && (
          <Subscription subscription={subscription} greenhouse={greenhouse} />
        )}
      </Container>
      {isEditName && (
        <EditModal onClose={() => setIsEditName(false)} greenhouse={greenhouse} />
      )}
    </Wrapper>
  );
};
