import React from 'react';

import { Icon } from 'components/_shared/Icon';
import { Divider } from 'components/_shared/Divider';

import { StyledStepsHeader, Header, HeaderWrap } from './StepsHeader.styles';

export const StepsHeader = () => {
  return (
    <StyledStepsHeader>
      <HeaderWrap>
        <Icon>House</Icon>
        <Header>New greenhouse</Header>
      </HeaderWrap>
      <Divider />
    </StyledStepsHeader>
  );
};
