import styled, { css } from 'styled-components';
import { em } from 'styles';
import { MapContainer } from 'react-leaflet';

export const StyledMapContainer = styled(MapContainer)`
  ${({ theme }) => css`
    flex-grow: 1;
    height: ${em(600)};
    max-width: ${em(600)};
    min-width: ${em(300)};
    border: 1px solid;
    background: ${theme.colors.white};
    .leaflet-bottom.leaflet-right {
      display: none;
    }
  `}
`;
