import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { em } from 'styles';

import { Button } from 'components/_shared/Button';
import { FormField, FormFieldMessages, FormFieldset } from 'components/_shared/Form';
import { Map } from 'components/Map';

const fieldMargin = 22;
const containerStyles = `
  display: flex;
  align-items: flex-start;
`;

export const Header = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.primary.p2};
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${rgba(theme.colors.primary.p2, 0.6)};
    font-size: ${em(14)};
  `}
`;

export const WarningText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primary.p1};
    font-size: ${em(12)};
    margin-bottom: ${em(12)};
  `}
`;

export const Area = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.primary.p1};
    font-size: ${em(12)};
    margin-bottom: ${em(4)};
  `}
`;

export const StyledGreenhousePlan = styled.div`
  min-width: ${em(850)};
  ${({ theme }) =>
    theme.breakpoints.sm(
      css`
        min-width: auto;
      `
    )};
`;

export const StyledFieldset = styled(FormFieldset)`
  width: ${em(130)};
  margin-top: ${em(fieldMargin)};
  margin-right: ${em(fieldMargin)};
`;

export const FieldsetWrap = styled.div`
  position: relative;
`;

export const Field = styled(FormField)`
  position: relative;
  margin-bottom: ${em(10)};
  ${FormFieldMessages} {
    margin: 0;
    padding: 0;
    position: absolute;
    top: ${em(64)};
    left: ${em(2)};
    & > p {
      line-height: 1.1em;
    }
  }
`;

export const Container = styled.div`
  ${containerStyles}
  ${({ theme }) =>
    theme.breakpoints.sm(
      css`
        flex-wrap: wrap;
        justify-content: center;
      `
    )};
`;

export const FieldsContainer = styled.div`
  ${containerStyles}
  flex-wrap: wrap;
  margin-left: ${em(fieldMargin)};
  & fieldset:not(:first-of-type) {
    margin-top: ${em(fieldMargin)};
  }
`;

export const DeleteRowBtn = styled(Button)`
  ${({ theme }) => css`
    position: absolute;
    top: ${em(35)};
    right: ${em(42)};
    width: ${em(25)};
    height: ${em(25)};
    padding: 0;
    margin: 0;
    & svg {
      color: ${theme.colors.danger};
    }
  `}
`;

export const AddRowBtn = styled(Button)`
  align-self: center;
  width: ${em(30)};
  height: ${em(30)};
  padding: 0;
  margin-top: ${em(50)};
`;

export const PlanFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: ${em(12)};
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledMap = styled(Map)`
  border-radius: 4px;
  border-color: ${({ theme }) => theme.colors.nature.n1};
  min-width: 380px;
  height: 380px;
  flex-grow: 3;
`;
