import styled, { css } from 'styled-components';
import { em } from 'styles';

import { Button } from 'components/_shared/Button';

export const Container = styled.div`
  margin: ${em(28)};
`;

export const TableHead = styled.thead`
  ${({ theme }) => css`
    color: ${theme.colors.nature.n4};
    font-size: ${em(20)};
    font-weight: ${theme.fontWeights[1]};
  `}
`;

export const SortingButton = styled(Button)`
  ${({ theme, down }) => css`
    flex-direction: row-reverse;
    font-size: ${em(13)};
    font-weight: ${theme.fontWeights[0]};
    color: ${theme.colors.nature.n4};
    & > span {
      margin-left: 0 !important;
      margin-right: 4px;
    }
    & svg {
      transform: rotate(${down ? '180deg' : '0'});
    }
  `}
`;

export const DeleteButton = styled(Button)`
  ${({ theme }) => css`
    background: ${theme.colors.primary.p3};
  `}
`;

export const StyledTable = styled.table`
  margin-bottom: ${em(40)};
  & th,
  td {
    padding: ${em(10)};
    font-size: ${em(14)};
  }
  & td {
    color: ${({ theme }) => theme.colors.black};
  }
`;
