import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { em } from 'styles';

export const Header = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.primary.p2};
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${rgba(theme.colors.primary.p2, 0.6)};
    font-size: ${em(14)};
  `}
`;

export const Subscriptions = styled.div`
  max-height: ${em(500)};
  overflow-y: auto;
`;

export const SubscriptionContainer = styled.div`
  ${({ selected, theme }) => css`
    display: flex;
    border-radius: ${em(5)};
    margin: ${em(12)} 0;
    cursor: pointer;
    overflow: hidden;
    border: solid 1px ${selected ? theme.colors.primary.p2 : 'transparent'};
  `}
`;

export const SubscriptionContent = styled.div`
  ${({ theme }) => css`
    flex-grow: 1;
    background: ${theme.colors.nature.n1};
    padding: ${em(12)};
  `}
`;

export const SubscriptionIndicator = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-shrink: 0;
    background: ${theme.colors.primary.p2};
    width: ${em(50)};
  `}
`;

export const CheckBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: ${em(20)};
    height ${em(20)};
    border-radius: 50%;
    background: ${theme.colors.white};
  `}
`;

export const SubscriptionHeader = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.primary.p2};
    font-size: ${em(20)};
  `}
`;

export const SubscriptionDescription = styled.pre`
  & * {
    all: revert;
  }
  font-size: ${em(13)};
  max-width: ${em(1000)};
  white-space: pre-wrap;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${em(20)};
`;
