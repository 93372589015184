import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { em } from 'styles';
import { Typography } from 'components/_shared/Typography';
import { Button } from 'components/_shared/Button';

export const Wrapper = styled.div`
  padding: ${em(15)} ${em(45)};
  flex-grow: 1;
  overflow-y: auto;
`;

export const InfoWrapper = styled.div`
  padding-left: ${em(12)};
  font-size: ${em(14)};
`;

export const InfoTitle = styled.span`
  color: ${({ theme }) => rgba(theme.colors.black, 0.7)};
  margin-right: ${em(10)};
`;

export const InfoValue = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.nature.n5};
  `}
`;

export const Title = styled(Typography).attrs({
  element: 'h2',
  variant: 'h3'
})`
  ${({ theme }) => css`
    margin-top: ${em(18)};
    margin-bottom: ${em(5)};
    color: ${theme.colors.nature.n3};
    font-weight: ${theme.fontWeights[1]};
    font-size: ${em(20)};
  `}
`;

export const StyledButton = styled(Button)`
  margin: ${em(20)} 0;
  color: ${({ theme, variantType }) => variantType === 'danger' && theme.colors.danger};
`;
