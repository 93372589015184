import React from 'react';

import { Header, Label, DateContainer, Description } from './Subscription.styles';

export const Subscription = ({ subscription, greenhouse }) => {
  return (
    <div>
      <Header>Subscription</Header>
      <Label>Name:</Label>
      <Header>{subscription.name}</Header>
      <Label>Detail:</Label>
      <Description dangerouslySetInnerHTML={{ __html: subscription.description }} />
      <Label>Date:</Label>
      <DateContainer>
        <Label>Start date:</Label>
        <span>{greenhouse.subscription_start_date}</span>
      </DateContainer>
      <DateContainer>
        <Label>Start date:</Label>
        <span>{greenhouse.subscription_start_date}</span>
      </DateContainer>
      <Label>
        The subscription will be automatically deactivated at the end of the subscription
        period.*
      </Label>
    </div>
  );
};
