import { configureStore } from '@reduxjs/toolkit';

import { userReducer } from './user';
import { mapReducer } from './map';
import { chartReducer } from './chart';
import { greenhouseReducer } from './greenhouse';

export const store = configureStore({
  reducer: {
    user: userReducer,
    map: mapReducer,
    chart: chartReducer,
    greenhouse: greenhouseReducer
  }
});
