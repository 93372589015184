import React, { useState, useRef, useEffect } from 'react';
import 'leaflet-editable';
import { CRS } from 'leaflet';
import { GeoJSON } from 'react-leaflet';
import { useSelector } from 'react-redux';

import { selectGreenhouse, useGreenhouseActions } from 'state';
import { themes } from 'styles';

import { StyledMapContainer } from './Map.styles';

const selectedFeatureColor = themes.themeDefault.colors.primary.p2;

export const Map = ({ data, ...props }) => {
  const { selectedZone } = useSelector(selectGreenhouse);
  const { setSelectedZone } = useGreenhouseActions();
  const geojsonRef = useRef(null);
  const [map, setMap] = useState(null);
  const [key, setKey] = useState(0);

  const geojsonEvents = {
    add: e => map.fitBounds(e.target.getBounds())
  };

  const geojsonFeatureHandler = (feature, layer) => {
    layer.on({
      click: ({ target }) => {
        if (target.feature.properties.zone_id === undefined) return;
        setSelectedZone(target.feature.properties.zone_id);
      }
    });
  };

  useEffect(() => setKey(x => x + 1), [data]);

  useEffect(() => {
    if (!geojsonRef.current) return;
    geojsonRef.current.resetStyle();
    if (selectedZone !== null) {
      const layers = geojsonRef.current.getLayers();
      const activeLayer = layers.find(
        ({ feature }) => feature.properties.zone_id === selectedZone
      );
      activeLayer.setStyle({ color: selectedFeatureColor });
    }
  }, [selectedZone]);

  return (
    <StyledMapContainer
      editable={false}
      center={[0, 0]}
      zoom={1}
      scrollWheelZoom={true}
      zoomControl={false}
      whenCreated={setMap}
      crs={CRS.Simple}
      {...props}
    >
      {map && data && (
        <GeoJSON
          key={key}
          data={data}
          ref={geojsonRef}
          eventHandlers={geojsonEvents}
          onEachFeature={geojsonFeatureHandler}
        />
      )}
    </StyledMapContainer>
  );
};
