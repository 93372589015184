import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectUser, useUserActions } from 'state';
import { Modal } from 'components/_shared/Modal';
import { Button } from 'components/_shared/Button';
// import { Logo } from 'components/Logo';
// import { Menu } from './Menu';
import { StyledHeader, ButtonWrapper } from './Header.styles';

export const Header = memo(function ({ ...props }) {
  const user = useSelector(selectUser);
  const { logout, toggleLogoutModal } = useUserActions();

  const handleLogOut = () => logout();

  return (
    <StyledHeader {...props}>
      {/* TODO: add later */}
      {/* <Logo /> */}
      {/* <Menu /> */}
      {user.email}
      {user.isShowLogoutModal && (
        <Modal
          header='Are you sure to log out from account?'
          textCenter={true}
          close={toggleLogoutModal}
        >
          <ButtonWrapper>
            <Button variant='secondary' onClick={toggleLogoutModal}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleLogOut}>
              Yes, log out
            </Button>
          </ButtonWrapper>
        </Modal>
      )}
    </StyledHeader>
  );
});
