import React, { memo, useMemo } from 'react';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';

import { CHART_CONFIG, CHART_LAYOUT, CHART_VALUES } from './constants';
import { getDateRangeLabel } from 'utils';

import { StyledSelect, Header, Title } from './GreenhouseStatistics.styles';

const Plot = createPlotlyComponent(Plotly);

const layout = {
  ...CHART_LAYOUT,
  width: 500,
  height: 200,
  bargap: 0.3,
  bargroupgap: 0.4,
  legend: { orientation: 'h', y: -0.1, x: -0.03, xanchor: 'left' }, // Move legend(trace name) to the bottom
  xaxis: { type: 'category', fixedrange: true },
  yaxis: { fixedrange: true }
};

export const TotalFlowersChart = memo(props => {
  const { axisData, dateList, onPeriodChange, selectedRange } = props;

  const selectItems = useMemo(() => {
    return dateList.map(dates => ({
      name: getDateRangeLabel(dates[0], dates[dates.length - 1]),
      value: JSON.stringify(dates)
    }));
  }, [dateList]);

  const data = useMemo(
    () =>
      CHART_VALUES.map((v, i) => ({
        name: v.name,
        marker: { color: v.color },
        type: 'bar',
        y: axisData.y[i],
        x: axisData.x,
        hovertemplate: ` <b>%{y}</b> <br> ${v.name} <extra></extra>`,
        dx: 3
      })),
    [axisData]
  );

  const handleSelectChange = ({ value }) => {
    onPeriodChange(JSON.parse(value));
  };

  return (
    <div>
      <Title>Total number of roses per day:</Title>
      <Header>For the last 7 days:</Header>
      <StyledSelect
        items={selectItems}
        value={JSON.stringify(selectedRange)}
        onSelect={handleSelectChange}
      />
      <div>
        <Plot data={data} layout={layout} config={CHART_CONFIG} />
      </div>
    </div>
  );
});
