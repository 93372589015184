import styled from 'styled-components';
import { em } from 'styles';

export const StyledStepsHeader = styled.div`
  margin-bottom: ${em(12)};
`;

export const HeaderWrap = styled.div`
  display: flex;
  margin-bottom: ${em(20)};
`;

export const Header = styled.h2`
  margin-left: ${em(5)};
  padding-top: ${em(4)};
  color: ${({ theme }) => theme.colors.primary.p2};
`;
