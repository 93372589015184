import React from 'react';
import * as Yup from 'yup';

import { useGreenhouseActions } from 'state';
import { Icon } from 'components/_shared/Icon';
import { Divider } from 'components/_shared/Divider';
import { Form } from 'components/_shared/Form';
import { Button } from 'components/_shared/Button';
import { FIELD_VALIDATION } from '_constants';

import {
  Modal,
  Header,
  Title,
  TitleWrap,
  FieldWrapper,
  StyledField,
  CloseButton
} from './EditModal.styles';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required(FIELD_VALIDATION.REQUIRED)
});

export const EditModal = ({ onClose, greenhouse }) => {
  const { updateLocation } = useGreenhouseActions();
  const handleSubmit = v => {
    updateLocation(greenhouse.id, { name: v.name.trim() });
    onClose();
  };

  return (
    <Modal>
      <Header>
        <TitleWrap>
          <Icon>House</Icon>
          <Title>New greenhouse</Title>
        </TitleWrap>
        <CloseButton icon='Cross' onClick={onClose} />
      </Header>
      <Divider />
      <Form
        validationSchema={validationSchema}
        initialValues={{ name: greenhouse.name }}
        onSubmit={handleSubmit}
        params={{ validateOnChange: true }}
      >
        {({ dirty }) => (
          <FieldWrapper>
            <StyledField
              label='Name of greenhouse'
              type='text'
              name='name'
              placeholder='Name*'
            />
            <Button type='submit' variant='primary' disabled={!dirty}>
              ok
            </Button>
          </FieldWrapper>
        )}
      </Form>
    </Modal>
  );
};
