import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useGreenhouseActions, selectGreenhouse } from 'state';
import { Icon } from 'components/_shared/Icon';

import { StepsButton } from '../NewGreenhouseSteps.styles';
import {
  ButtonsWrapper,
  CheckBox,
  Header,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionDescription,
  SubscriptionHeader,
  SubscriptionIndicator,
  Subscriptions,
  Title
} from './GreenhouseSubscription.styles';

export const GreenhouseSubscription = props => {
  const { onBack, subscriptionId, onSubscriptionChange, onSubmit } = props;
  const { loadSubscriptions } = useGreenhouseActions();
  const { subscriptions } = useSelector(selectGreenhouse);

  useEffect(() => loadSubscriptions(), [loadSubscriptions]);

  return (
    <div>
      <Header>Subscription</Header>
      <Title>Selecting the type of subscription</Title>
      <Subscriptions>
        {subscriptions?.map(({ name, description, id }) => {
          const selected = subscriptionId === id;
          return (
            <SubscriptionContainer
              key={id}
              selected={selected}
              onClick={() => onSubscriptionChange({ subscriptionId: id })}
            >
              <SubscriptionContent>
                <SubscriptionHeader>{name}</SubscriptionHeader>
                <SubscriptionDescription
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </SubscriptionContent>
              <SubscriptionIndicator>
                <CheckBox>{selected && <Icon>Check</Icon>}</CheckBox>
              </SubscriptionIndicator>
            </SubscriptionContainer>
          );
        })}
      </Subscriptions>
      <ButtonsWrapper>
        <StepsButton variant='primary' onClick={onBack} margin={15}>
          Back
        </StepsButton>
        <StepsButton variant='primary' onClick={onSubmit}>
          Create new greenhouse
        </StepsButton>
      </ButtonsWrapper>
    </div>
  );
};
