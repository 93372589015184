import styled, { css } from 'styled-components';

import { VIEWPORT_PADDING } from '_constants';

import { em, rem } from 'styles';

import { StyledMenu } from './Menu';

export const StyledHeader = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: ${theme.colors.primary.p1};
    color: ${theme.colors.nature.n0};
    box-shadow: ${theme.shadows()[0]};
    padding: ${rem([theme.spacing[3], VIEWPORT_PADDING])};

    ${StyledMenu} {
      margin-right: auto;
      margin-left: ${em(25)};
    }
  `}
`;

export const ButtonWrapper = styled.div`
  button:first-child {
    margin-right: ${em(16)};
    border: ${({ theme }) => theme.borders.default(theme.fontSizes[2])};
  }
`;
