import React, { useMemo } from 'react';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';
import { isNil } from 'lodash-es';

import { ALL_ZONES, CHART_VALUES, CHART_LAYOUT, CHART_CONFIG } from './constants';
import { MONTHS } from '_constants';

import { StyledSelect, Title } from './GreenhouseStatistics.styles';

const Plot = createPlotlyComponent(Plotly);

const layout = {
  ...CHART_LAYOUT,
  height: 220,
  width: 500,
  legend: { x: -0.5, y: 1 }
};

export const AverageFlowersChart = props => {
  const { zoneStatistic, period, selectedZone, selectedDay, onDayChange } = props;

  const selectItems = useMemo(
    () =>
      period
        .map(date => {
          const d = new Date(date);
          const name = `${d.getDate()} ${MONTHS[d.getMonth()]}, ${d.getFullYear()}`;
          return { value: date, name };
        })
        .reverse(),
    [period]
  );

  const values = useMemo(() => {
    const zoneId = String(selectedZone ?? ALL_ZONES);
    const oneZone = zoneStatistic.find(
      item => item.date === selectedDay && item.zone_external_id === zoneId
    );
    if (!oneZone) return [];
    const bud = oneZone.average_detections.bud_stage_0;
    const partiallyOpen = oneZone.average_detections.bud_stage_1;
    const fullyOpen = oneZone.average_detections.bud_stage_2;
    return [bud, partiallyOpen, fullyOpen];
  }, [selectedDay, selectedZone, zoneStatistic]);

  const handleSelectChange = ({ value }) => onDayChange(value);
  const zoneName = isNil(selectedZone) ? 'greenhouse' : 'row';

  return (
    <div>
      <Title>Average number of roses per 1 m²</Title>
      <StyledSelect
        items={selectItems}
        value={selectedDay}
        onSelect={handleSelectChange}
      />
      <div>
        <Plot
          data={[
            {
              values: values,
              labels: CHART_VALUES.map(({ name }) => name),
              marker: { colors: CHART_VALUES.map(({ color }) => color) },
              textposition: 'none',
              domain: { column: 1 },
              hovertemplate: ` Average number of %{label} <br> per ${zoneName} per 1 m² <br> %{value:.2f} <br><extra></extra>`,
              hole: 0.7,
              type: 'pie'
            }
          ]}
          layout={layout}
          config={CHART_CONFIG}
        />
      </div>
    </div>
  );
};
