import React from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash-es';

import { Map } from 'components/Map';
import { useGreenhouseActions, selectGreenhouse } from 'state';

import {
  Wrapper,
  Header,
  Title,
  Subtitle,
  StyledButton,
  RowInfo
} from './GreenhousePlan.styles';

export const GreenhousePlan = () => {
  const { locations, selectedGreenhouse, selectedZone } = useSelector(selectGreenhouse);
  const { setSelectedZone } = useGreenhouseActions();

  const greenhouse = locations.find(({ id }) => id === selectedGreenhouse);
  const firstZone = greenhouse?.plan.features[0].properties.zone_id;

  const handleGreenhouseClick = () => setSelectedZone(null);
  const handleRowClick = () => setSelectedZone(firstZone);

  if (!greenhouse) return null;

  return (
    <Wrapper>
      <Map data={greenhouse.plan} />
      <Header>
        <Title>Greenhouse plan</Title>
        <Subtitle>View statistics on:</Subtitle>
        <StyledButton
          variant='secondary'
          isActive={isNil(selectedZone)}
          onClick={handleGreenhouseClick}
        >
          Greenhouse
        </StyledButton>
        <StyledButton
          variant='secondary'
          isActive={!isNil(selectedZone)}
          onClick={handleRowClick}
        >
          Row
        </StyledButton>
        <RowInfo selected>Select Row</RowInfo>
        <RowInfo>Row under monitoring</RowInfo>
      </Header>
    </Wrapper>
  );
};
