import React from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from 'state';
import { ROUTES } from '_constants';
import { Button } from 'components/_shared/Button';

import {
  Wrapper,
  InfoTitle,
  InfoValue,
  Title,
  InfoWrapper,
  StyledButton
} from './Profile.styles';

export const Profile = () => {
  const user = useSelector(selectUser);

  return (
    <Wrapper>
      <Title>Your information</Title>
      <InfoWrapper>
        <InfoTitle>Email address:</InfoTitle>
        <InfoValue>{user.email}</InfoValue>
      </InfoWrapper>
      <Title>Subscription</Title>
      {/* TODO: onClick show modal with subscriptions */}
      <Button variant='primary' disabled>
        Details
      </Button>
      <Title>Administration</Title>
      <InfoWrapper>
        <StyledButton icon='Eye' to={ROUTES.RESET_PASSWORD}>
          Change password
        </StyledButton>
      </InfoWrapper>
    </Wrapper>
  );
};
