import styled, { css } from 'styled-components';
import { em } from 'styles';

import { Button } from 'components/_shared/Button';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin-top: ${em(40)};
    padding: ${em(20)};
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    background: ${theme.colors.white};
  `}
`;

export const Header = styled.div`
  margin-left: ${em(26)};
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    margin-right: ${em(12)};
    font-size: ${em(20)};
    color: ${theme.colors.black};
  `}
`;

export const Subtitle = styled.span`
  font-size: ${em(12)};
`;

export const StyledButton = styled(Button)`
  ${({ theme, isActive }) => css`
    margin: ${em(16)} 0;
    display: block;
    border-radius: 5px;
    ${isActive &&
    css`
      background: ${theme.colors.primary.p3};
      color: ${theme.colors.black};
    `};
    &:hover {
      background: ${theme.colors.primary.p1};
      color: ${theme.colors.white} !important;
    }
  `}
`;

export const RowInfo = styled.div`
  ${({ theme, selected }) => css`
    margin: ${em(20)} 0;
    font-size: ${em(12)};
    &::before {
      content: '';
      display: inline-block;
      width: ${em(10)};
      height: ${em(10)};
      border-radius: 50%;
      margin-right: ${em(7)};
      background: ${selected ? theme.colors.primary.p2 : theme.colors.primary.p3};
    }
  `}
`;
