import React from 'react';

import { Page } from 'components/_shared/Page';
import { Sidebar } from 'components/_shared/Sidebar';
import { GreenhouseManagement as GreenhouseManagementContent } from 'components/GreenhouseManagement';

export const GreenhouseManagement = props => {
  return (
    <Page fullHeight {...props}>
      <Sidebar />
      <GreenhouseManagementContent />
    </Page>
  );
};
