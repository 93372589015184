import styled, { css } from 'styled-components';

import { StepsButton } from '../NewGreenhouseSteps.styles';
import { FormField } from 'components/_shared/Form';
import { em } from 'styles';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const StyledField = styled(FormField)`
  width: ${em(200)};
  margin-bottom: ${em(10)};
`;

export const FieldText = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary.p2};
    font-size: ${em(12)};
    margin-top: ${em(15)};
    max-width: ${em(500)};
  `}
`;

export const StyledButton = styled(StepsButton)`
  flex-shrink: 0;
`;
