import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_STATE = {
  locations: [],
  selectedGreenhouse: null,
  selectedZone: null,
  missionDates: [],
  zoneStatistic: [],
  subscriptions: null
};

const greenhouseSlice = createSlice({
  name: 'greenhouse',
  initialState: DEFAULT_STATE,
  reducers: {
    setLocations: (state, action) => {
      state.locations = action.payload.locations;
      state.selectedGreenhouse = action.payload.selected;
    },
    addLocation: (state, action) => {
      state.locations.push(action.payload);
      state.selectedGreenhouse = action.payload.id;
    },
    deleteLocation: (state, action) => {
      state.locations = state.locations.filter(({ id }) => id !== action.payload);
    },
    updateLocation: (state, action) => {
      const location = action.payload;
      const index = state.locations.findIndex(({ id }) => id === location.id);
      state.locations[index] = location;
    },
    setSelectedGreenhouseId: (state, action) => {
      if (state.selectedGreenhouse === action.payload) return;
      state.selectedGreenhouse = action.payload;
      state.selectedZone = null;
      state.zoneStatistic = [];
    },
    setSelectedZone: (state, action) => {
      state.selectedZone = action.payload;
    },
    setDefaultState: () => DEFAULT_STATE,
    setMissionDates: (state, action) => {
      state.missionDates = action.payload;
    },
    setZoneStatistic: (state, action) => {
      state.zoneStatistic = action.payload;
    },
    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload;
    }
  }
});

export const { reducer: greenhouseReducer, actions: greenhouseActions } = greenhouseSlice;

export const selectGreenhouse = state => state.greenhouse;
