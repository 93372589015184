import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route as ReactRoute } from 'react-router-dom';

import { ROUTES, USER_PERMISSIONS } from '_constants';
import { selectUser } from 'state';

export const Route = ({ component: Component, isPrivate = false, ...props }) => {
  const user = useSelector(selectUser);
  const isAdmin = user.permissions?.includes(USER_PERMISSIONS.ADD);

  const renderRoute = props => {
    if (isPrivate && !isAdmin) return <Redirect to={ROUTES.ROOT} />;
    return <Component {...props} />;
  };

  return <ReactRoute {...props} render={renderRoute} />;
};
