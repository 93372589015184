import styled, { css } from 'styled-components';
import { em } from 'styles';

import { Link } from 'components/_shared/Link';

export const StyledLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.colors.nature.n4};
  font-size: ${em(13)};
`;

export const GreenHousesHeader = styled.div`
  display: flex;
  padding: 0 ${em(10)};
  margin-bottom: ${em(12)};
`;

export const Title = styled.span`
  margin-left: ${em(10)};
  margin-right: ${em(25)};
`;

export const HouseList = styled.ul`
  padding: ${em(10)} 0;
  max-height: 50vh;
  overflow-y: auto;
`;

export const HouseListItem = styled.li`
  ${({ theme, selected }) => css`
    padding: ${em(5)} ${em(30)};
    transition: ${theme.transitions.fast};
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background: ${theme.colors.nature.n1};
    }

    ${selected &&
    css`
      color: ${theme.colors.black};
      background: ${theme.colors.primary.p3};
      &:hover {
        color: ${theme.colors.nature.n0};
        background: ${theme.colors.primary.p1};
      }
    `}
  `}
`;
