import styled from 'styled-components';
import { em } from 'styles';

import { shouldForwardProp } from 'utils';
import { Button } from 'components/_shared/Button';

export const StyledButton = styled(Button).withConfig({ shouldForwardProp })`
  justify-content: flex-start;
  width: 100%;
  padding: ${em(8)} ${em(10)};
  font-size: ${em(16)};
  background-color: ${({ isActive }) => (isActive ? '#C7CEFF' : 'inherit')};
`;

export const NewGreenhouse = styled.div`
  margin: ${em(18)} 0;
  text-align: center;
`;

export const AddButton = styled(Button)`
  min-width: ${em(240)};
`;
