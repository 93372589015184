import styled, { css } from 'styled-components';
import { em } from 'styles';

import { Button } from 'components/_shared/Button';
import { Map } from 'components/Map';

export const Wrapper = styled.div`
  padding: ${em(20)};
  flex-grow: 1;
  position: relative;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  & > div:first-child {
    margin-right: ${em(60)};
    min-width: ${em(350)};
  }
  ${({ theme }) =>
    theme.breakpoints.s(
      css`
        flex-wrap: wrap;
        & > div:first-child {
          margin-right: 0;
          margin-bottom: ${em(20)};
        }
      `
    )};
`;

export const Header = styled.h2`
  ${({ theme, bold }) => css`
    color: ${theme.colors.primary.p2};
    font-size: ${em(20)};
    font-weight: ${theme.fontWeights[bold ? 1 : 0]};
  `}
`;

export const Size = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.primary.p1};
    font-size: ${em(12)};
  `}
`;

export const StyledMap = styled(Map)`
  border: none;
  margin: ${em(10)} 0;
`;

export const EditButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.colors.primary.p1};
  `}
`;

export const BackButton = styled(Button)`
  min-width: ${em(125)};
`;
